import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

type FaqConfig = {
  question: string
  answer: string
}

const faqConfig: FaqConfig[] = [
  {
    question:
      'Our data is sensitive. Can we install LightTag on our premises ? ',
    answer:
      'Yes! LightTag is frequently deployed to customer sites and does not require a connection to the internet.',
  },
  {
    question: 'Can we embed LightTag in our own product ? ',
    answer:
      'Yes. You can expose LightTag directly to your end users, or contact us for white-labeled integrations.',
  },
  {
    question: 'Will LightTag use or sell my data ?  ',
    answer:
      'Never. Our business is annotation software and we never use or sell your data, as described in our Terms of Service.',
  },
  {
    question:
      'Does LightTag provide annotators as well as annotation software?',
    answer:
      'No. LightTag provides the software to manage and empower your team, but not the team itself. If you need ' +
      "a managed team as well, we're happy to connect you to our partners.",
  },
  {
    question: "Our data isn't in English, does LightTag support our language?",
    answer:
      'Yes. LightTag supports any language, including right to left languages like Arabic and languages without word boundaries like Chinese.',
  },
]

const FaqSection = () => {
  return (
    <Box
      style={{
        maxWidth: '1020px',
        margin: '0 auto',
        padding: '70px 0 140px 0',
      }}
    >
      <Typography
        variant="h5"
        style={{
          margin: '0 0 20px 88px',
        }}
      >
        FAQ
      </Typography>
      {faqConfig.map(faq => {
        return (
          <ExpansionPanel elevation={0}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{faq.question}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="subtitle1">{faq.answer}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </Box>
  )
}

export default FaqSection
