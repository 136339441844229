import React from 'react'
import Layout from '../../components/layout'
import FeaturesGrid from '../../components/features_grid/features_grid'
import UsersSection from '../../components/users_section/users_section'
import TestimonialCarousel from '../../components/testimonial_carousel/testimonial_carousel'
import FaqSection from '../../components/faq_section/faq_section'

const Features = () => (
  <Layout>
    <FeaturesGrid />
    <TestimonialCarousel />
    <UsersSection />
    <FaqSection />
  </Layout>
)

export default Features
