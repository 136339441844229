import React from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import CheckIcon from '@material-ui/icons/Check'

const FeatureTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#2C2C2C',
    padding: '8px 12px',
    maxWidth: '160px',
    borderRadius: '0',
  },
}))(Tooltip)

const featureCol = {
  color: '#FFFFFF',
  width: '195px',
}

const featureColSm = {
  maxWidth: '98px',
  height: '36px',
  margin: '0 auto 10px auto',
}

const useStyles = makeStyles(theme => ({
  featureItem: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },

  hintCol: {
    width: '24px',
    marginRight: '50px',
    opacity: 0.2,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  matrixCol: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  starterCol: {
    ...featureCol,
    backgroundColor: '#414141',
    marginRight: '40px',

    [theme.breakpoints.down('sm')]: {
      ...featureColSm,
      marginRight: '20px',
    },
  },

  growthCol: {
    ...featureCol,
    backgroundColor: '#7891E6',
    marginRight: '40px',

    [theme.breakpoints.down('sm')]: {
      ...featureColSm,
      marginRight: '20px',
    },
  },

  scaleCol: {
    ...featureCol,
    backgroundColor: '#A571CD',

    [theme.breakpoints.down('sm')]: {
      ...featureColSm,
    },
  },
}))

type FeatureMatrixItem = {
  value?: string
  check?: boolean
}

export type FeatureItemProps = {
  name?: string
  hidden?: boolean
  hintText?: string
  hideHint?: boolean
  header?: boolean
  starter?: FeatureMatrixItem
  growth?: FeatureMatrixItem
  scale?: FeatureMatrixItem
}
export type FeatureItem = {
  kind: 'feature'
  header?: false
  name: string
  hintText: string
} & FeatureItemProps
export type FeatureItemCategory = {
  kind: 'category'
  hideHint: true
  header: true
  features: FeatureItem[]
} & FeatureItemProps

const FeatureItem = (props: FeatureItem | FeatureItemCategory) => {
  const {
    name,
    hidden,
    hintText,
    hideHint,
    header,
    starter,
    growth,
    scale,
  } = props
  const classes = useStyles()
  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.featureItem}
        style={{
          minHeight: '8px',
          display: hidden ? 'none' : '',
        }}
      >
        <Grid
          style={{
            flexGrow: 1,
          }}
        >
          <Typography variant={header ? 'subtitle2' : 'subtitle1'}>
            {name}
          </Typography>
        </Grid>
        <Grid container alignItems="center" className={classes.hintCol}>
          <FeatureTooltip arrow placement="top" title={hintText || ''}>
            <Box
              style={{
                height: '24px',
                cursor: 'pointer',
              }}
            >
              <HelpOutlineOutlinedIcon
                style={{ display: hideHint ? 'none' : '' }}
              />
            </Box>
          </FeatureTooltip>
        </Grid>
        <Grid
          item
          direction="row"
          justify="space-between"
          className={classes.matrixCol}
          style={{
            display: header ? 'none' : '',
          }}
        >
          <Grid
            container
            alignContent="center"
            justify="center"
            className={classes.starterCol}
          >
            {starter?.value ? (
              <Typography variant="subtitle1">{starter.value}</Typography>
            ) : (
              <CheckIcon style={{ display: !starter?.check ? 'none' : '' }} />
            )}
          </Grid>
          <Grid
            container
            alignContent="center"
            justify="center"
            className={classes.growthCol}
          >
            {growth?.value ? (
              <Typography variant="subtitle1">{growth.value}</Typography>
            ) : (
              <CheckIcon style={{ display: !growth?.check ? 'none' : '' }} />
            )}
          </Grid>
          <Grid
            container
            alignContent="center"
            justify="center"
            className={classes.scaleCol}
          >
            {scale?.value ? (
              <Typography variant="subtitle1">{scale.value}</Typography>
            ) : (
              <CheckIcon style={{ display: !scale?.check ? 'none' : '' }} />
            )}
          </Grid>
        </Grid>
      </Grid>
      {props.kind === 'category'
        ? props.features.map(feature => <FeatureItem {...feature} />)
        : null}
    </>
  )
}

export default FeatureItem
