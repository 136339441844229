import React from 'react'
import {
  Grid,
  makeStyles,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import FeatureItem from '../feature_item/feature_item'
import FeaturesArray from './features_config'
import { createMuiTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { freemiumSignupURL } from '../../utils/urls'
import pricingPlans from '../../utils/pricingPlans'
import { HrefButton } from '../common/HrefButton'

const callToActionButtonBase = {
  fontFamily: '"Ubuntu", "Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  width: '195px',
}

const useStyles = makeStyles(theme => ({
  featureGrid: {
    maxWidth: '1047px',
    margin: '0 auto',
    padding: '150px 0 100px',

    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 20px 0',
    },
  },

  featureGridTitles: {
    marginBottom: '40px',

    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
      marginBottom: '0',
    },
  },

  matrixTitles: {
    display: 'flex',
    flexGrow: 1,
    padding: '0 50px',

    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0 0',
    },
  },

  matrixTitle: {
    width: '100px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },

  matrixFirstRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  callToActionButtons: {
    marginTop: '100px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },

  callToActionButton: {
    ...callToActionButtonBase,

    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },

  callToActionButtonMargin: {
    ...callToActionButtonBase,
    marginRight: '40px',

    [theme.breakpoints.down('sm')]: {
      margin: '0 0 20px 0',
    },
  },
}))

const FeaturesGrid = () => {
  const classes = useStyles()
  const matcher = useMediaQuery(useTheme().breakpoints.down('sm'))
  return (
    <Grid container direction="row" className={classes.featureGrid}>
      <Grid container direction="column">
        <Grid
          container
          justify="space-between"
          direction="row"
          className={classes.featureGridTitles}
        >
          <Typography
            variant="h6"
            style={{
              maxWidth: '382px',
              flexGrow: 1,
            }}
          >
            Features
          </Typography>
          <Grid item justify="space-between" className={classes.matrixTitles}>
            <Typography variant="h6" className={classes.matrixTitle}>
              Individual
            </Typography>
            <Typography variant="h6" className={classes.matrixTitle}>
              Team
            </Typography>
            <Typography variant="h6" className={classes.matrixTitle}>
              OnPrem
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Grid>
            {/* One blank feature item to associate the colors with the plan names */}
            <FeatureItem hideHint hidden={matcher} kind={'feature'} />
            {FeaturesArray.map(featureCategory => {
              return (
                <FeatureItem key={featureCategory.name} {...featureCategory} />
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        className={classes.callToActionButtons}
      >
        <ThemeProvider
          theme={createMuiTheme({
            palette: {
              primary: {
                main: '#000000',
              },
            },
          })}
        >
          <HrefButton
            href={pricingPlans.visibleIndividualPlan.signupUrl}
            disableElevation
            color="primary"
            variant="outlined"
            className={classes.callToActionButtonMargin}
          >
            Start For Free
          </HrefButton>
        </ThemeProvider>
        <ThemeProvider
          theme={createMuiTheme({
            palette: {
              primary: {
                main: '#7891E6',
              },
            },
          })}
        >
          <HrefButton
            href={pricingPlans.visibleTeamPlan.signupUrl}
            disableElevation
            color="primary"
            variant="contained"
            className={classes.callToActionButtonMargin}
          >
            Start For Free
          </HrefButton>
        </ThemeProvider>
        <ThemeProvider
          theme={createMuiTheme({
            palette: {
              primary: {
                main: '#A571CD',
              },
            },
          })}
        >
          <Button
            href="/contact"
            disableElevation
            color="primary"
            variant="contained"
            className={classes.callToActionButton}
            style={{}}
          >
            Contact Sales
          </Button>
        </ThemeProvider>
      </Grid>
    </Grid>
  )
}

export default FeaturesGrid
