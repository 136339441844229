import { FeatureItemCategory } from '../feature_item/feature_item'

const Core: FeatureItemCategory = {
  kind: 'category',
  name: 'Core',
  header: true,
  hideHint: true,
  features: [
    {
      kind: 'feature',
      name: 'Annotators',
      hintText: 'The number of people changing data',
      starter: {
        value: '1',
      },
      growth: {
        value: '3',
      },
      scale: {
        value: 'Unlimited',
      },
    },
    {
      kind: 'feature',
      name: 'Annotations Per Month',
      hintText:
        'One annotation is either an entity, classification or relationship',
      starter: {
        value: '500',
      },
      growth: {
        value: '5,000',
      },
      scale: {
        value: '20,000',
      },
    },
    {
      kind: 'feature',
      name: 'Additional Annotations',
      hintText: 'Cost per annotation beyond the monthly allocation',
      starter: {
        value: 'None',
      },
      growth: {
        value: '$0.02',
      },
      scale: {
        value: '$0.01',
      },
    },
    {
      kind: 'feature',
      name: 'Additional Annotators',
      hintText: 'Monthly price of each additional annotator',
      starter: {
        value: '$65',
      },
      growth: {
        value: '$50',
      },
      scale: {
        value: '$0',
      },
    },
    {
      kind: 'feature',
      name: 'Datasets',
      hintText: 'How many distinct collections of data can be stored',
      starter: {
        value: '2',
      },
      growth: {
        value: '10',
      },
      scale: {
        value: 'Unlimited',
      },
    },
    {
      kind: 'feature',
      name: 'Support',
      hintText: 'How can we help you?',
      starter: {
        value: 'Email and in-app',
      },
      growth: {
        value: 'Front of the queue',
      },
      scale: {
        value: 'Custom SLAs',
      },
    },
  ],
}
const AnnotationTypes: FeatureItemCategory = {
  kind: 'category',
  name: 'Annotation Types',
  header: true,
  hideHint: true,
  features: [
    {
      kind: 'feature',
      name: 'Span Annotation',
      hintText: 'Annotate spans of text ',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Document Classifications',
      hintText:
        'Classify a document into a single class such as Positive/Negative',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Document Tagging',
      hintText:
        'Tag a document with multiple tags such as "Support" and "Account Renewal"',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Relationships',
      hintText:
        'Use a drag and drop interface to form complex relations between entities',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
  ],
}
const AnnotatorProductivity: FeatureItemCategory = {
  kind: 'category',
  name: 'Annotator Productivity',
  header: true,
  hideHint: true,
  features: [
    {
      kind: 'feature',
      name: 'Very Long Class Lists',
      hintText:
        "LightTag's searchable class lists make it easy to work with hundreds of classes",
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },

    {
      kind: 'feature',
      name: 'Keyboard Shortcuts',
      hintText:
        'Easily select text, annotate entities and apply classes with the keyboard',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Phrase and Subword Annotations',
      hintText:
        'LightTag makes no tokenization assumptions and you can annotate subwords and phrases',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Document Metadata',
      hintText:
        'Help your team annotate by displaying additional document metadata',
      starter: {
        check: true,
      },

      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Guidelines',
      hintText:
        'Prevent mistakes by writing rich guidelines and embed them in your annotators task',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },

    {
      kind: 'feature',
      name: 'Auto Save',
      hintText:
        "Work is backed up to annotators' machines so they can pause and resume without losing work",
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Pre-Annotations',
      hintText: 'Bring your Own pre-annotations or let LightTag learn from you',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Search',
      hintText: 'Find the needles in your haystack with lightning fast search',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
  ],
}
const ForManagers: FeatureItemCategory = {
  kind: 'category',
  name: 'Project Management',
  header: true,
  hideHint: true,
  features: [
    {
      kind: 'feature',
      name: 'Multiple Annotators Per Document',
      hintText: 'Assign multiple workers per document to control for quality',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Automatic Scheduling & Task Assignment',
      hintText:
        'You say what data should be labeled and by whom, LightTag makes sure it happens',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Teams',
      hintText:
        'Assign work to particular teams (such as by language or security clearance)',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Productivity Reports',
      hintText:
        'Know how much each annotator produced, how long it took and how good it was',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Role-Based Access Control',
      hintText:
        'Assign roles and define access controls to subsets of your data',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
  ],
}
const QualityControl: FeatureItemCategory = {
  kind: 'category',
  name: 'Quality Control',
  header: true,
  hideHint: true,
  features: [
    {
      kind: 'feature',
      name: 'Inter-Annotator Agreement Reports',
      hintText:
        'LightTag provides real time calculations of inter-annotator agreement',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Review & Adjudication',
      hintText: 'See and correct what your annotators annotated',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Precision and Recall Reports',
      hintText: 'Get detailed metrics on the quality of each annotator',
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
  ],
}
const ForEngineers: FeatureItemCategory = {
  kind: 'category',
  hideHint: true,
  header: true,
  name: 'Data And Compliance',

  features: [
    {
      kind: 'feature',
      name: 'Backups',
      hintText:
        "LightTag manages frequent encrypted snapshots of your data so you don't have to",
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'JSON and CSV Uploads',
      hintText: 'Use the GUI to upload JSONs and CSV with your data',
      starter: {
        check: true,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'API',
      hintText:
        "Control your projects and download results with LightTag's API",
      starter: {
        check: false,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Document Metadata',
      hintText:
        "Your document's metadata is always attached to the document and available on download",
      starter: {
        check: false,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'Integrate Your Models',
      hintText:
        'Use the API to upload predictions from your models as pre-annotations and get reporting',
      starter: {
        check: false,
      },
      growth: {
        check: true,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'European Data Center (GDPR)',
      hintText:
        'LightTag can host you in a European data center for full GDPR compliance',
      starter: {
        check: false,
      },
      growth: {
        check: false,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'On-premise Deployments',
      hintText:
        'LightTag can be deployed on your infrastructure in a fully air-gaped environment',
      starter: {
        check: false,
      },
      growth: {
        check: false,
      },
      scale: {
        check: true,
      },
    },
    {
      kind: 'feature',
      name: 'LDAP/SSO',
      hintText: 'Full integration with your LDAP/SSO systems ',
      starter: {
        check: false,
      },
      growth: {
        check: false,
      },
      scale: {
        value: 'Available',
      },
    },
    {
      kind: 'feature',
      name: 'Audit Logs',
      hintText: 'Immutable audit logs of every data access and modification',
      starter: {
        check: false,
      },
      growth: {
        check: false,
      },
      scale: {
        value: 'Available',
      },
    },
  ],
}

const FeaturesArray: FeatureItemCategory[] = [
  // Core,
  AnnotationTypes,
  AnnotatorProductivity,
  ForManagers,
  QualityControl,
  ForEngineers,
]

export default FeaturesArray
